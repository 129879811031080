//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {};
  },
  props: {
    btnActive: {
      type: Boolean,
      default: true
    },
    styleColor: Boolean
  },
  methods: {}
};
