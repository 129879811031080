//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dateFunc from './dateFunc'
import moment from 'moment';

export default {
  props : {
    currentMonth : {},
    titleFormat  : {},
    firstDay     : {},
    monthNames   : {},
    locale       : {},
    btnShow:{type:Boolean,default:true}
  },
  data () {
    return {
    }
  },
  computed: {
    //年月的标题
    title () {
      if (!this.currentMonth) return;        
      let  date = new Date(this.currentMonth);
      let teamTime = date.getFullYear() + ' 年' + ' '+(date.getMonth() + 1) + ' 月'
      return teamTime
    }
  },
  methods : {
    goPrev () {
      var newMonth = moment(this.currentMonth).subtract(1, 'months').startOf('month');
      this.$emit('change', newMonth);
    },
    goNext () {
      var newMonth = moment(this.currentMonth).add(1, 'months').startOf('month');
      this.$emit('change', newMonth);
    }
  }
}
